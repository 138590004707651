
import Vue from "vue";

import { userMapGetters } from "@/store/modules/user";
import { IUserRoleStrings } from "@/types";

interface IMenuItem {
  path: string;
  text: string;
  type: IUserRoleStrings[];
}

export default Vue.extend({
  name: "AdminUserMenu",
  computed: {
    ...userMapGetters(["getEditingUser"]),
    currentPage(): string {
      return this.$route.path;
    },
    userId(): string {
      return this.$route.params.user_id;
    },
    menuItems(): any[] {
      let items = [
        {
          path: `/admin/users/${this.userId}/edit`,
          text: `User Info`,
          type: ["all"]
        },
        {
          path: `/admin/users/${this.userId}/activities`,
          text: `User Activities`,
          type: ["all"]
        }
      ];
      if (this.$getCurrentUser && this.$getCurrentUser.role !== "admin") {
        items.pop();
      }
      return items;
    },
    menuItemsByRole(): IMenuItem[] {
      if (!this.getEditingUser) return [];
      const { role } = this.getEditingUser;
      return this.menuItems.filter(
        (mi: any) => mi.type.includes(role) || mi.type.includes("all")
      );
    }
  }
});
